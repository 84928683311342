// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-templates-bio-page-js": () => import("./../../../src/templates/bio-page.js" /* webpackChunkName: "component---src-templates-bio-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-project-index-js": () => import("./../../../src/templates/project-index.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-turtle-film-index-js": () => import("./../../../src/templates/turtle-film-index.js" /* webpackChunkName: "component---src-templates-turtle-film-index-js" */),
  "component---src-templates-turtlefilm-page-js": () => import("./../../../src/templates/turtlefilm-page.js" /* webpackChunkName: "component---src-templates-turtlefilm-page-js" */)
}

